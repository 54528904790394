import React, { useState, useEffect } from 'react';
import Slider from './Slider'; 
import '../global.css';
import axios from 'axios';

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getScrollitemsDataForUsers', {
          params: { school: schoolName },
        });
        
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          setData(response.data);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return <div>Error: {error.message}</div>; 
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (!data || data === " ") {
    return <div>No data available</div>;
  }

  return (
    <Slider features={data?.users || []} fontColor={localStorage.getItem("heroFontColor")} heading="Users" />
  );
};

export default Users;
