import React, { useState, useEffect } from 'react';
import '../Fonts/fontello-icon.css';
import '../global.css';
import axios from 'axios';

const Footer = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getFooterData', {
          params: { school: schoolName },
        });
        if (response.data.success == false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          const logoUrl = response.data.logo.logo;
          // Check file type and handle accordingly
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl);
          } else {
            setLogoContent(logoUrl); // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        setError("Error loading footer data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [schoolName]);

  const fetchAndModifySVG = async (svgUrl) => {
    try {
      const response = await fetch(svgUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        // Change the color of all paths
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', '#ffe6c8');
          path.setAttribute('stroke', '#ffe6c8'); 
        });
         // Set the width and height of the SVG
        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        svgElement.setAttribute('transform', 'rotateY(45deg)'); 
        // Serialize the modified SVG back to a string
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        
        // Set the modified SVG content to state
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use smooth scrolling
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark-footer' : 'bg-transparent'}`;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data || data === " ") return <div>...Loading</div>;

  const filteredMenu = data.menu.filter(item => {
    if (item.name === 'Writings' && subDomain !== 'virtupreplabs') {
      return false;
    }
    return true;
  });

  return (
    <footer className="text-virtu-header bg-black footer-padding pt-8rem">
      <div className="footer-container vp-container d-flex gap-5 flex-column">
        <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center gap-2 gap-lg-5">
          <div className="logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5 me-0 m-5">
            <h1 className="siteLogo">
              <a href={data.logo.href}>
                {logoContent ? (
                  // Check if logoContent is SVG or image
                  logoContent.includes('<svg') ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: logoContent }}
                      className="svg-rotate"
                      style={{ display: 'block' }}
                    />
                  ) : (
                    <img src={logoContent} alt="Logo" width="50px" height="20px" />
                  )
                ) : (
                  <i className={data.logo.iconClass}></i>
                )}
              </a>
            </h1>
            <a className="navbar-brand bg-black  text-virtu-header common-textshadow montser-lrg m-0" href={data.logo.brandHref}>
              <span>{data.logo.brandName} </span>
            </a>
          </div>
          <ul className="navbar-nav flex-wrap d-flex flex-row align-items-start align-items-md-center me-auto mb-2 mb-lg-0 gap-sm-4 gap-2 footer-menu w-100">
            {filteredMenu.map((item, index) => (
              <li className="nav-item nav-item-space" key={index}>
                {!item.subMenu ? (
                  <a className="nav-link bg-black text-virtu-header common-textshadow montser-mdm-2" href={item.href}>
                    <span className="text-decorator-underline">{item.name}</span>
                  </a>
                ) : (
                  <div className="nav-item dropdown">
                    <a
                      className="nav-link bg-black text-virtu-header d-flex align-items-center gap-2 montser-mdm-2 common-textshadow"
                      href="/"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleDropdownToggle}
                    >
                      <span className="text-decorator-underline text-virtu-header">{item.name}</span>
                      <div className={`${isDropdownOpen ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className="demo-icon icon-opened-folder dropdown-icon-size"></i>
                      </div>
                    </a>
                    <ul className={dropdownMenuClassName} aria-labelledby={`navbarDropdown${index}`}>
                      {item.subMenu.map((subItem, subIndex) => {
                        const hrefPrefix = item.name === 'Features' ? 'feature' : 'educator';
                        return (
                          <li key={subIndex}>
                            <a className="dropdown-item-effect montser-mdm-2 bg-black text-virtu-header" href={`/${hrefPrefix}=${subItem.name}`}>
                              {subItem.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-start gap-3 gap-md-5 align-items-md-center mt-4 mt-md-0">
          <div className="nav-item minw-265 w-100 me-xl-4 ms-xl-4 me-0 text-start">
            <a className="nav-link text-virtu-header bg-black bg-dark-footer montser-mdm-2 common-textshadow" href="/">
            <img src="/logos/VP3d.svg" class="" alt="Logo" className='vp-logo'/>
              <span>{data.footerNote}</span>
            </a>
          </div>
          <ul className="navbar-nav  d-flex align-center me-auto mb-2 mb-lg-0 flex-row gap-4">
            {data.socialLinks.map((link, index) => (
              <li className="nav-item" key={index}>
                <a className="nav-link bg-black bg-dark-footer copy-right-text text-decorator-underline montser-mdm-2 common-textshadow" href={link.href}>
                  <span className="text-virtu-header text-decorator-underline">{link.name}</span>
                </a>
              </li>
            ))}
          </ul>
          <ul className="navbar-nav d-flex align-center gap-3 flex-row">
            <li className="nav-item back-to-top">
              <a
                href="#"
                className="nav-link copy-right-text-top bg-black bg-dark-footer montser-mdm-2 common-textshadow pe-auto"
                onClick={scrollToTop}
              >
                <span className="text-decorator-underline text-virtu-header">Back to Top</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
