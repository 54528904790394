import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../global.css';

const Slider = ({ features, heading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [displayFeatures, setDisplayFeatures] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsToShow(1);
      } else {
        setItemsToShow(4);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const adjustedFeatures = [...features];
    if (features.length % itemsToShow !== 0) {
      const dummyCount = itemsToShow - (features.length % itemsToShow);
      for (let i = 0; i < dummyCount; i++) {
        adjustedFeatures.push({
          link: '#',
          imageSrc: '/dummy.png',
          title: '',
          description: ''
        });
      }
    }
    setDisplayFeatures(adjustedFeatures);
  }, [features, itemsToShow]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, displayFeatures.length - itemsToShow));
  };

  const calculateTransform = () => {
    const totalItems = displayFeatures.length;
    const itemsToDisplay = Math.min(totalItems, itemsToShow);

    if (currentIndex === 0) {
      return `translateX(0%)`;
    }

    if (currentIndex >= totalItems - itemsToDisplay) {
      const lastVisibleIndex = totalItems - itemsToDisplay;
      const shiftIndex = Math.max(lastVisibleIndex - 1, 0);
      const shiftPercentage = shiftIndex * (100 / itemsToDisplay);
      return `translateX(calc(-${shiftPercentage}%))`;
    }

    return `translateX(calc(-${currentIndex * (100 / itemsToDisplay)}%))`;
  };

  return (
    <div className="container-fluid bg-black p-0 pt-5">
      <div className="scroll-items bg-black">
        <div className="d-flex justify-content-between align-items-center pr-1 vp-container">
          <h2 className="custom-large-heading light-col text-start pt-3 mb-0">{heading}</h2>
          <div>
            <button className="btn btn-outline-primary me-2" onClick={prevSlide}>
              <i className="fas fa-arrow-left"></i>
            </button>
            {currentIndex < displayFeatures.length - itemsToShow && (
              <button className="btn btn-outline-primary" onClick={nextSlide}>
                <i className="fas fa-arrow-right"></i>
              </button>
            )}
          </div>
        </div>
        <div className="slider-content pt-2" style={{ transform: calculateTransform() }}>
          {displayFeatures.map((feature, index) => {
            const hrefPrefix = heading === "Features" ? "feature" : "educator";
            return (
              <div key={index} className="slider-item col-md-6 col-lg-3 my-4 mx-4">
                <a href={`/${hrefPrefix}=${feature.title}`} className="text-decoration-none">
                  <img
                    src={feature.imageSrc}
                    alt="image not found"
                    className="img-fluid rounded-top rounded-0 common-boxshadow"
                    style={{ width: '94%' }}
                  />
                  <div className="slider-overlay card-overlay">
                    <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-0-8rem common-textshadow">
                      {feature.title}
                    </span>
                    <br />
                    <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic-0-7-5rem">
                      {feature.description}
                    </span>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Slider;
