import React, { useState, useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import axios from 'axios';

const Navbar = ({ bgColorClass }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          const logoUrl = response.data.logo.logo;
          // Check file type and handle accordingly
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl);
          } else {
            setLogoContent(logoUrl); // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        setError("Error loading data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [schoolName]);

  const fetchAndModifySVG = async (svgUrl) => {
    try {
      console.log('Fetching SVG from URL:', svgUrl); // Log the URL
      const response = await fetch(svgUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        // Change the color of all paths
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', '#ffe6c8');
          path.setAttribute('stroke', '#ffe6c8'); 
        });
         // Set the width and height of the SVG
        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        // Serialize the modified SVG back to a string
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        
        // Set the modified SVG content to state
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
      if (openDropdownIndex !== null && !e.target.closest('.dropdown-menu') && !e.target.closest('.nav-item')) {
        setOpenDropdownIndex(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed, openDropdownIndex]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleJoinClick = () => {
    const joinLink = `https://${subDomain}.app.virtupreplabs.com/join`;
    window.open(joinLink, '_blank');
  };

  const handleLoginClick = () => {
    const loginLink = `https://${subDomain}.app.virtupreplabs.com`;
    window.open(loginLink, '_blank');
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark' : 'bg-1c2933-90opa'}`;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <nav className={`navbar navbar-expand-lg position-fixed ht-10vh ${scrolling ? 'navbar-scrolling' : ''} ${bgColorClass}`}>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-0 maxw-265 w-100 me-xl-5 me-0'>
          <h1 className="siteLogo">
              <a href={data.logo.href}>
                {logoContent ? (
                  // Check if logoContent is SVG or image
                  logoContent.includes('<svg') ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: logoContent }}
                      className="svg-rotate"
                      style={{ display: 'block' }}
                    />
                  ) : (
                    <img src={logoContent} alt="Logo" width="50px" height="20px" />
                  )
                ) : (
                  <i className={data.logo.iconClass}></i>
                )}
              </a>
            </h1>
          <a className="navbar-brand text-virtu-header common-textshadow montser-lrg ms-2" href={data.brand.href}>
            <span>{data.brand.name}</span>
          </a>
        </div>
        <button
          className="navbar-toggler border-0 text-white"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          <span className={`${scrolling ? 'text-white' : ''}`}>
            <span className="text-virtu-header f-ui-1">Menu</span>
          </span>
        </button>
        <div className={`collapse navbar-collapse mobile-menu mobile-bg-color ${!isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav d-flex align-items-start align-items-lg-center center me-auto mb-2 mb-lg-0 header-txt-margin gap-4">
            {data.menu.map((item, index) => {
              if (item.name === "Writings" && subDomain !== "virtupreplabs") {
                return null;
              }
              return (
                <li className="nav-item" key={index}>
                  {!item.subMenu ? (
                    <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href={item.href}>
                      <span className='text-decorator-underline'>{item.name}</span>
                    </a>
                  ) : (
                    <div
                      className="d-flex align-items-center gap-2 text-yellow montser-mdm-2 common-textshadow"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleDropdownToggle(index)}
                    >
                      <span className='navbar-brand text-virtu-header common-textshadow montser-lrg m-0 nav-item-span nav-context-menu-title'>{item.name}</span>
                      <div className={`${openDropdownIndex === index ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className='demo-icon icon-opened-folder dropdown-icon-size'></i>
                      </div>
                    </div>
                  )}
                  {item.subMenu && (
                    <ul className={`dropdown-menu ${openDropdownIndex === index ? 'show' : ''}`} aria-labelledby={`navbarDropdown${index}`}>
                      {item.subMenu.map((subItem, subIndex) => {
                        const hrefPrefix = item.name === "Features" ? "feature" : "educator";
                        return (
                          <li key={subIndex}>
                            <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href={`/${hrefPrefix}=${subItem.name}`}>
                              <span className='text-decorator-underline'>{subItem.name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <ul className="navbar-nav d-flex align-center gap-3 flex-row justify-content-end">
            <li className="nav-item px-2">
              <a onClick={handleLoginClick} className="nav-link montser-lrg m-0 text-yellow d-flex align-center justify-content-center gap-2 cursor-pointer" rel="noopener noreferrer">
                <span className="text-decorator-underline">{data.auth.loginText}</span>
                <div className="rotated-icon"><i className="demo-icon icon-up-arrow dropdown-icon-size"></i></div>
              </a>
            </li>
            <li className="nav-item px-2">
              <a onClick={handleJoinClick} className="nav-link montser-lrg m-0 text-yellow d-flex align-center justify-content-center gap-2 cursor-pointer" rel="noopener noreferrer">
                <span className="text-decorator-underline">{data.auth.joinText}</span>
                <div className="rotated-icon"><i className="demo-icon icon-up-arrow dropdown-icon-size"></i></div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;