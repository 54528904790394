import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';
import axios from 'axios';

const Aboutus = () => {
  const pageName = "aboutPage";
  const buttonText = "Home";

  ////////axios api/////////
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getAboutUsPageDetails', {
          params: { school: schoolName },
        });
        if (response.data.success == false) {
          setError(response.data.message);
        } else {
          setData(response.data);
        }
      } catch (err) {
        setError("Error loading about us page details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    const elements = document.getElementsByClassName("text-yellow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("aboutSectionActive");
  }, [schoolName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data || data === " ") return <div>...Loading</div>;

  return (
    <div className='p-0'>
      {data && (
        <VideoBackground
          videoLink={data.videoLink}
          buttonText={buttonText}
          pageName={pageName}
        />
      )}
      <div style={{ height: '100%', paddingTop: '20px' }} className='bg-black'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <div className='content-margin '>
            {data && data.sections.map((section, index) => (
              <div key={index}>
                <h4 className='mb-1 section-margin text-start montser-mdm-1-5rem' style={{marginLeft:'12.4vw'}}>{section.heading} <style>{`h4.custom-large-heading {font-size: 1rem !important;}`}</style></h4>
                {section.paragraphs.map((paragraph, idx) => (
                  <div key={idx} className='align-items-center'>
                    {paragraph.split('.').map((sentence, sentenceIdx) => (
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh' key={sentenceIdx}>
                        {sentence.trim()}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
