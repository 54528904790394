import React from 'react';
import './videobackground.css';
import img1 from "../logo.svg"

const EvidenceModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    const dynamicVideoLink = "videos/Virtu Prep Landing 1 Vid.mp4";
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-body">
                    <div className="evidence-content d-flex flex-column">
                        <div className="container-e px-5">
                            <div className="d-flex mt-2 px-4 gap-4">
                                <div className="img-l" >
                                    <img 
                                        src="https://virtuprep-gallary.nyc3.cdn.digitaloceanspaces.com/Adaptive Learning 2 Square.png" 
                                        alt="Profile" 
                                        className="img-fluid rounded-circle"
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center text-left">
                                    <span className="montser-lrg">
                                        "I think that the VirtuPrep platform shows how the insightful application of learning science can blend with an engaging approach to student motivation in order to really advance the way we think about education. I'm confident students who use the system will be energized to learn!"
                                    </span>
                                    <div className="text-end d-flex flex-column montser-mdm">
                                        <span className="small lh-1 text-decoration-underline font-italic">Danielle Leek,</span>
                                        <span className="small lh-1 text-decoration-underline font-italic">Ph.D, MBA Lecturer, John's Hopkins</span>
                                        <span className="small lh-1 text-decoration-underline font-italic">Instructional Faculty, College Unbound</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-e px-5 d-flex justify-content-around">

                                <div className="graph-svg align-items-center">
                                    <img 
                                        src="https://virtuprep-gallary.nyc3.digitaloceanspaces.com/Virtu Prep Data.svg" 
                                        alt="Graph" 
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="graph-svg align-items-center text-left flex-column">
                                    <span className="mb-0 montser-lrg pb-3 text-left">Charles Youn,<br/> Managing Director at Teach For America</span>
                                    <video autoPlay loop muted playsInline style={{width:"100%", height:"80%"}}>
                                        <source src={dynamicVideoLink} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvidenceModal;
