import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import "@fontsource/montserrat";
import axios from 'axios';
import AppNavigations from './navigations/AppNavigations';

const App = ({hostname}) => {
  useEffect(() => {
    document.title = hostname;
  }, [hostname]);

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); 
    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  } 

  
  return (

    <BrowserRouter>
        <AppNavigations/>
    </BrowserRouter>
  );
};

export default App;
