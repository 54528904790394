import React, { useRef, useState } from 'react';
import './videobackground.css';

const EvidenceModal = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);  // State to manage mute/unmute

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();  // Close the modal when clicking outside the modal content
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);  // Toggle the mute state
        if (videoRef.current) {
            videoRef.current.muted = !videoRef.current.muted;  // Set video muted property
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-content" ref={modalRef}>
                <div className="modal-header">
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-body">
                    <div className="evidence-content d-flex flex-column">
                        <div className="container-e ps-2">
                            <div className="d-flex mt-2 pr-4 gap-4">
                                <div className="img-l mb-3">
                                    <img 
                                        src="logos/Danielle Leek.jpeg" 
                                        alt="Profile" 
                                        className="img-fluid rounded-circle"
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center text-left">
                                    <span className="montser-lrg">
                                        "I think that the VirtuPrep platform shows how the insightful application of learning science can blend with an engaging approach to student motivation in order to really advance the way we think about education. I'm confident students who use the system will be energized to learn!"
                                    </span>
                                    <div className="text-end d-flex flex-column montser-mdm">
                                        <span className="small lh-1 text-decoration-underline font-italic">Danielle Leek,</span>
                                        <span className="small lh-1 text-decoration-underline font-italic">Ph.D, MBA Lecturer, John's Hopkins</span>
                                        <span className="small lh-1 text-decoration-underline font-italic">Instructional Faculty, College Unbound</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-e px-5 d-flex flex-wrap justify-content-around">
                            <div className="graph-svg align-items-center mb-3">
                                <img 
                                    src="https://virtuprep-gallary.nyc3.digitaloceanspaces.com/Virtu Prep Data.svg" 
                                    alt="Graph" 
                                    className="img-fluid"
                                />
                            </div>
                            <div className="graph-svg align-items-center text-left flex-column mb-3">
                                <span className="mb-0 montser-mdm pb-3 text-left">
                                    Charles Youn,<br></br> <span className="font-weight-normal">Managing Director at Teach For America</span>
                                </span>
                                <div className="video-container" style={{ position: 'relative' }}>
                                    <video 
                                        autoPlay 
                                        loop  
                                        playsInline
                                        muted={isMuted}  // Set initial mute state
                                        ref={videoRef}
                                        className="w-100" 
                                        style={{ maxHeight: "80%" }}
                                    >
                                        <source src="videos/Teach For America Testimonial.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button 
                                        className="mute-toggle" 
                                        onClick={toggleMute} 
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            border: 'none',
                                            color: '#fff',
                                            padding: '5px',
                                            borderRadius: '50%'
                                        }}
                                    >
                                        {isMuted ? '🔇' : '🔊'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvidenceModal;
