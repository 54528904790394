import React, { useState, useRef, useEffect } from 'react';
import '../global.css';
import './videobackground.css'; // Make sure to import your CSS
import EvidenceModal from './EvidenceModal'; // Import the EvidenceModal component
import "../global.css"

const VideoBackgroundforLanding = ({ videoLink, buttonText, pageName }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false); // State for modal

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = 0.5;
    }
  }, []);

  const handleAboutClick = () => {
    const destinationUrl = buttonText ==="Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };

  const handleJoinClick = () => {
    window.open("https://learning.virtupreplabs.com/join", "_blank");
  };

  const redirectOnChange = () => {
    const selectElement = document.getElementById("schoolSelect");
    const selectedValue = selectElement.options[selectElement.selectedIndex].value;

    switch (selectedValue) {
      case 'learningmeasurementlab':
        window.open('http://learningmeasurementlab.virtupreplabs.com');
        break;
      case 'SoftSkills':
        window.open('https://softskills.virtupreplabs.com');
        break;
      case 'Rethink':
        window.open('http://rethink.virtupreplabs.com');
        break;
      case 'careerPrep':
        window.open('http://careerprep.virtupreplabs.com');
        break;
      case 'neoTest':
        window.open('http://neotestprep.virtupreplabs.com');
        break;
      case 'healthyBrains':
        window.open('http://healthybrain.virtupreplabs.com');
        break;
      case 'learnWithoutGate':
        window.open('http://learningwithoutgatekeepers.virtupreplabs.com');
        break;
      case 'RealMerit':
        window.open('http://realmerit.virtupreplabs.com');
        break;
      case 'Torah':
        window.open('http://torah.virtupreplabs.com');
        break;
      default:
    }
  };

  if (pageName !== "mainPage") return null; // Return nothing if not on mainPage

  const handleEvidenceClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="video-background video-background-landing" id="mainPage">
      <video className="landing-video" ref={videoRef} autoPlay loop muted playsInline>
        <source src={videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">
        <div className='vp-container video-bg-1 z-index-9 mb-auto w-100 h-100 d-flex align-items-center'>
          <div className="video-text justify-content-center text-yellow heading-left gap-3 gap-md-5 d-flex w-100 h-100">
            <div className="mainPageContainer d-flex align-center">
              <div className="container-fluid main-content position-relative">
                <div className="row justify-content-center align-items-center h-100 d-flex flex-column gap-5">
                  <div className="row mt-3">
                    <div className="col-6 d-flex align-items-center justify-content-end ">
                      <span className="text-sm light-col common-textshadow mobile-heading text-nowrap montser-lrg">Guaranteed Improvement</span>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      <button type="button" className="p-1 rounded-top rounded-0 montser-lrg btn-md" onClick={handleEvidenceClick}>Evidence</button>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6 d-flex align-items-center justify-content-end">
                      <span className="light-col common-textshadow mobile-heading text-nowrap montser-lrg">Elevate your school</span>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      <button type="button" className="p-1 rounded-top rounded-0 montser-lrg btn-sm" onClick={handleJoinClick}>Join</button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6 d-flex align-items-center justify-content-end">
                      <span className="light-col common-textshadow mobile-heading text-nowrap montser-lrg">Find your elevated school</span>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      <select className="rounded-top rounded-0 montser-lrg select-btn p-1" id="schoolSelect" onChange={redirectOnChange}>
                        <option value="">Find your school</option>
                        <option value="Rethink">Rethink</option>
                        <option value="RealMerit">RealMerit</option>
                        <option value="SoftSkills">Soft Skills</option>
                        <option value="neoTest">Neo-Test Prep</option>
                        <option value="healthyBrains">Healthy Brains</option>
                        <option value="learningmeasurementlab">Learning Measurement Lab</option>
                        <option value="Torah">Torah School</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='vp-container d-flex mb-4 px-5 justify-content-between align-items-center'>
          <div className='about'>
            <button type="button" className="outline-lightgreen py-0 rounded-top rounded-0 btn-lg btn-block btn montser-mdm-2" onClick={handleAboutClick}>About Virtu-Prep</button>
          </div>
          <div className="d-flex">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-light montser-mdm-2 mx-2 ">Facebook</a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-light montser-mdm-2 mx-2 ">LinkedIn</a>
          </div>
        </div>

      </div>
      <EvidenceModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default VideoBackgroundforLanding;