import React, {  useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import BlogDashboard from '../blogs/BlogDashboard'
import Aitutor from '../blogs/Aitutor'
import Blog_1 from '../blogs/Blog_1'

import Education from '../blogs/Education'
import Repetition from '../blogs/Repetition'
import Shadows from '../blogs/Shadows'
import Kaleidoscope from '../blogs/Kaleidoscope'
import Matrix from '../blogs/Matrix'
import Neuroscience from '../blogs/Neuroscience'
import ResearchDashboard from '../research/ResearchDashboard'
import Aboutus from '../about_us/Aboutus'
import VideoBackground from '../components/VideoBackground'
import ScrollItems from '../components/ScrollItems'
import useSession from '../hooks/useSession'

import Features from '../sections/Features';
import Roles from '../sections/Roles';
import axios from 'axios' 
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import VideoBackgroundforLanding from '../components/VideoBackgroundForLanding'

export default function AppNavigations() {
  const type = window.location.href.split('=')[0].split('/').pop();
  const [videoLink, setVideoLink] = useState('');
  const [overlayText, setOverlayText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dynamicVideoLink = "videos/Virtu Prep Landing 1 Vid.mp4";
  const { setSubDomain } = useSession();
  setSubDomain();
  const subDomain = localStorage.getItem('subDomain');
  const pageName = "mainPage";
  const buttonText = "About Us";
  let schoolName = subDomain;
  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getVideoLink', {
          params: { school: schoolName },
        });
        if (response.data.success!=false) {
          setVideoLink(response.data.videoLink);
          setOverlayText(response.data.overlayText);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("Error loading video link");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [schoolName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='mainSectionDiv'>
      {subDomain !== 'virtupreplabs' && (
        <Navbar bgColorClass="bg-black" />
      )}
      {subDomain === 'virtupreplabs' && (
        <Navbar bgColorClass="bg-transparent" />
      )}
      <Routes>
        {type === 'feature' && (
          <Route path="/:feature" element={<Features dataPath="adaptiveLearningData.json" />} />
        )}
        {type === 'educator' && (
          <Route path="/:role" element={<Roles dataPath="parentData.json" />} />
        )}
        {subDomain !== 'virtupreplabs' && (
          <Route path="/videoBackground" element={<VideoBackground />} />
        )}
        {subDomain === 'virtupreplabs' && (
          <Route path="/VideoBackgroundforLanding" element={<VideoBackgroundforLanding />} />
        )}
        <Route path="/blog" element={<Blog_1 />} />
        <Route path="/blogdashboard" element={<BlogDashboard dataPath="/blogDashboardData.json" />} />
        <Route path="/blog1" element={<Aitutor />} />
        <Route path="/blog2" element={<Education />} />
        <Route path="/blog3" element={<Repetition />} />
        <Route path="/blog4" element={<Shadows />} />
        <Route path="/blog5" element={<Kaleidoscope />} />
        <Route path="/blog6" element={<Matrix />} />
        <Route path="/blog7" element={<Neuroscience />} />
        <Route path="/research" element={<ResearchDashboard dataPath="/researchDashboardData.json" />} />
        <Route path="/about-us" element={<Aboutus />} />
        {subDomain !== 'virtupreplabs' && (
          <Route path="/" element={<>
            <VideoBackground videoLink={videoLink} overlayText={overlayText} buttonText={buttonText} pageName={pageName} />
            <ScrollItems />
          </>} />
        )}
        {subDomain === 'virtupreplabs' && (
          <Route path="/" element={<>
            <VideoBackgroundforLanding videoLink={videoLink} buttonText={buttonText} pageName={pageName} />
          </>} />
        )}
      </Routes>
      {subDomain !== 'virtupreplabs' && (
        <Footer />
      )}
    </div>
  );
}
