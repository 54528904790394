import { useEffect , useContext} from "react";

export default function useSession() {

  useEffect(() => {
    window.addEventListener('beforeunload', handleRemoveToken);
    window.addEventListener('popstate', handleRemoveToken);
  }, [localStorage]);

  const setSubDomain = () => {
    const subDomain = window.location.hostname.split(".")[0]
    if(subDomain == "dyd"){
      localStorage.setItem("subDomain", "dydacademy");
    }else if(subDomain == "uwish"){
      // localStorage.setItem("subDomain", "UWishAcademy");
      window.location.href = " https://virtuprep.com/"
    }else{
      localStorage.setItem("subDomain", subDomain);
    }  
    
  }

  const handleRemoveToken = () => {
    console.log('Token removed');
    localStorage.removeItem('subDomain');
  };

  return {  setSubDomain, handleRemoveToken };
}
