import React from "react";
import { PopupButton } from "react-calendly";

const MyPopupbutton = () => {
  return (
    <div className="App">
    <PopupButton type="button" className="outline-lightgreen py-0 rounded-top rounded-0 meet-us-btn btn-lg btn-block btn btn-outline-light montser-mdm-2 col-ffe6c8 ml-auto"
      url="https://calendly.com/virtuprep/15min?hide_gdpr_banner=1&background_color=1c2933&text_color=ffe6c8&primary_color=ebb663'"
      rootElement={document.getElementById("root")}
      text="Meet us"
      
    >
      <span className="text-yellow">Meet us</span>
    </PopupButton>
  </div>
  );
};

export default MyPopupbutton;