import React, { useState, useRef, useEffect } from 'react';
import '../global.css';
import './videobackground.css'; // Make sure to import your CSS
import EvidenceModal from './EvidenceModal'; // Import the EvidenceModal component

const VideoBackgroundforLanding = ({ videoLink, buttonText, pageName }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false); // State for modal

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = 0.5;
    }
  }, []);

  const handleAboutClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };

  const handleJoinClick = () => {
    window.open("https://learning.virtupreplabs.com/join", "_blank");
  };

  const redirectOnChange = (event) => {
    const selectedValue = event.target.textContent; // Get the selected value

    if (!selectedValue) return; // Do nothing if no school is selected
    switch (selectedValue) {
      case 'learningmeasurementlab':
        window.open('http://learningmeasurementlab.virtupreplabs.com');
        break;
      case 'Soft Skills':
        window.open('https://softskills.virtupreplabs.com');
        break;
      case 'Rethink':
        window.open('http://rethink.virtupreplabs.com');
        break;
      case 'careerPrep':
        window.open('http://careerprep.virtupreplabs.com');
        break;
      case 'Neo-Test Prep':
        window.open('http://neotestprep.virtupreplabs.com');
        break;
      case 'Healthy Brains':
        window.open('http://healthybrain.virtupreplabs.com');
        break;
      case 'Learning Measurement Lab':
        window.open('http://learningwithoutgatekeepers.virtupreplabs.com');
        break;
      case 'RealMerit':
        window.open('http://realmerit.virtupreplabs.com');
        break;
      case 'Torah School':
        window.open('http://torah.virtupreplabs.com');
        break;
      case 'DYD Academy':
        window.open('http://dyd.academy');
        break;  
      default:
    }
  };

  if (pageName !== "mainPage") return null; // Return nothing if not on mainPage

  const handleEvidenceClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="video-background video-background-landing" id="mainPage">
      <video className="landing-video" ref={videoRef} autoPlay loop muted playsInline>
        <source src={videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="v-video-overlay">
        <div className='vp-container video-bg-1 z-index-9 w-100 d-flex align-items-center'>
          <div className="video-text justify-content-center text-yellow heading-left gap-3 gap-md-5 d-flex w-100 px-3 px-md-5"> {/* Add padding here */}
            <div className="mainPageContainer d-flex align-center">
              <div className="container-fluid main-content position-relative">
                <div className="row justify-content-center align-items-center h-100 d-flex flex-column gap-5">

                  {/* Only visible on mobile devices */}
                  <div className="d-md-none d-flex flex-column align-items-center justify-content-center">
                    <button type="button" className="p-1 mb-5 montser-lrg btn-md common-boxshadow rounded-top rounded-0" onClick={handleEvidenceClick} 
                    style={{backgroundColor:'#1c2933',color:localStorage.getItem("heroFontColor"),width:'15rem',height:'3rem'}}>
                      Evidence
                    </button>
                    <button type="button" className="p-1 mb-5 montser-lrg btn-md common-boxshadow rounded-top rounded-0" 
                    onClick={handleJoinClick} 
                    style={{color:'#1C2933',width:'15rem',height:'3rem'}}>
                      Join
                    </button>
                    <div className="dropdown">
                      <button className="btn-secondary dropdown-toggle montser-lrg btn-md common-boxshadow rounded-top rounded-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" 
                      style={{backgroundColor:'#1c2933',color:localStorage.getItem("heroFontColor"),width:'15rem',height:'3rem'}}>
                        Find your school
                      </button>
                      <ul className="dropdown-menu scrollable-dropdown" style={{background:'#e2eecc'}}>
                        {["Rethink", "RealMerit", "Soft Skills", "Neo-Test Prep", "Healthy Brains", "Learning Measurement Lab", "Torah School", "DYD Academy"].map((school) => (
                          <li className="montser-lrg" value={school} key={school} onClick={redirectOnChange}>
                            <a className="dropdown-item monster-mdm common-textshadow" style={{fontSize:'1.8vh'}} onClick={redirectOnChange} value={school}>
                              {school}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* desktop view*/}
                  <div className="d-none d-md-flex flex-column justify-content-center">
                    {/* Row 0 */}
                    <div className="row mt-3 px-3 px-md-0 mb-4"> 
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="text-sm light-col common-textshadow mobile-heading text-nowrap montser-lrg-heading-sml pt-5">
                          Neuroscience to help your students grow
                        </span>
                      </div>
                    </div>
                    {/* Row 1 */}
                    <div className="row mt-3 px-3 px-md-0"> 
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <span className="text-sm light-col common-textshadow mobile-heading text-nowrap montser-lrg">
                          Guaranteed Improvement
                        </span>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-start">
                      <button
                          type="button"
                          className="p-1 montser-lrg btn-md common-boxshadow btn-hover-effect rounded-top rounded-0"
                          onClick={handleEvidenceClick}
                          style={{
                            backgroundColor: '#1c2933',
                            color: localStorage.getItem("heroFontColor"),
                            '--background-color': '#1c2933', // Set background color for CSS variable
                            '--font-color': localStorage.getItem("heroFontColor"), // Set font color for CSS variable
                            width: '15rem',
                            height: '3rem'
                          }}
                        >
                          Evidence
                        </button>

                      </div>
                    </div>

                    {/* Row 2 */}
                    <div className="row mt-3 px-3 px-md-0"> 
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <span className="light-col common-textshadow mobile-heading text-nowrap montser-lrg">
                          Elevate your school
                        </span>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-start">
                      <button
                        type="button"
                        className="p-1 montser-lrg btn-md common-boxshadow btn-hover-effect rounded-top rounded-0"
                        onClick={handleJoinClick}
                        style={{
                          color:'#1C2933',
                          '--background-color': '#83B06D', // Set background color
                          '--font-color': '#1C2933', // Set font color
                          width: '15rem',
                          height: '3rem'
                        }}
                      >
                        Join
                      </button>

                      </div>
                    </div>

                    {/* Row 3 */}
                    <div className="row mt-3 px-3 px-md-0">
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        <span className="light-col common-textshadow mobile-heading text-nowrap montser-lrg">
                          Find your elevated school
                        </span>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-start">
                        <div className="dropdown">
                        <button
                          className="btn-secondary rounded-top rounded-0 dropdown-toggle montser-lrg btn-md common-boxshadow btn-hover-effect"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            backgroundColor: '#1c2933',
                            color: localStorage.getItem("heroFontColor"),
                            '--background-color': '#1c2933',
                            '--font-color': localStorage.getItem("heroFontColor"),
                            width: '15rem',
                            height: '3rem'
                          }}
                        >
                          Find your school
                        </button>

                          <ul className="dropdown-menu scrollable-dropdown" style={{background:'#e2eecc'}}>
                            {["Rethink", "RealMerit", "Soft Skills", "Neo-Test Prep", "Healthy Brains", "Learning Measurement Lab", "Torah School", "DYD Academy"].map((school) => (
                              <li className="montser-lrg" value={school} key={school} onClick={redirectOnChange}>
                                <a className="dropdown-item monster-mdm common-textshadow" style={{fontSize:'1.8vh'}} onClick={redirectOnChange} value={school}>
                                  {school}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Button and social links */}
        <div className='vp-container socialLinks d-flex mb-4 px-5  flex-column flex-md-row'>
  <div className='v-about mb-3 mb-md-0 w-100'>
    <button
      type="button"
      className="outline-lightgreen py-0 rounded-top rounded-0 btn-lg btn-block btn montser-sml-bold"
      onClick={handleAboutClick}>
      About Virtu Prep
    </button>
    {/* Hide this on mobile, show on desktop */}
    <span className="col-ffe6c8 montser-mdm-2 pt-3 d-none d-md-block ps-3">Virtu Prep © 2015-2024</span>
  </div>

  {/* Social links */}
  <div className="flex-column flex-md-row w-100 d-md-flex justify-content-end">
    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-light montser-mdm-2 mx-2">Facebook</a>
    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-light montser-mdm-2 mx-2">LinkedIn</a>
  </div>

  {/* Show this only on mobile */}
  <div><span className="col-ffe6c8 montser-mdm-2 pt-3 d-block d-md-none">Virtu Prep © 2015-2024</span></div>
</div>

      </div>
      {/* Modal for Evidence */}
      <EvidenceModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default VideoBackgroundforLanding;
