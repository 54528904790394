import React, { useEffect, useRef, useState } from 'react';
import '../global.css';
import './videobackground.css';

/**
 * VideoBackground Component
 * 
 * This component renders a video background with overlay text and a button.
 * The button redirects the user to a different page based on the button text.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.videoLink - The URL of the video to be played in the background.
 * @param {string} props.overlaytext - The text to be displayed as an overlay on the video.
 * @param {string} props.buttonText - The text to be displayed on the button.
 * @param {string} props.pageName - The name of the page (not used in the current implementation).
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VideoBackground = ({ videoLink, overlaytext, buttonText, pageName }) => {
  const videoRef = useRef(null);

  /**
   * Handles the click event of the button.
   * Redirects the user to the home page or about-us page based on the button text.
   */
  const handleJoinClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };

  /**
   * Splits the overlay text into two lines by word count.
   * 
   * @returns {Array} An array containing two strings, each representing a line of text.
   */
  function splitTextByWordCount() {
    if (!overlaytext) {
      return ['', ''];
    }
    const words = overlaytext.split(' ');
    const midPoint = Math.floor(words.length / 2);
    return [words.slice(0, midPoint).join(' '), words.slice(midPoint).join(' ')];
  }

  const [firstLine, secondLine] = splitTextByWordCount(overlaytext);

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className='m-4'> 
            <video ref={videoRef} autoPlay loop muted playsInline>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-overlay">
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-mdm-italic ft-5vh'>{firstLine}</h1>
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-mdm-italic ft-5vh mt-5'>{secondLine}</h1>
            
            <div className="about mt-5">
              <button 
                type="button" 
                className="outline-lightgreen py-0 rounded-top rounded-0 btn-lg btn-block btn btn-outline-light montser-lrg-bold-0-8rem col-ffe6c8" 
                onClick={handleJoinClick}
              >
                {buttonText}
              </button>
            </div>   
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoBackground;
