import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const BlogDashboard = ({ dataPath }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(dataPath)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
    
    const elements = document.getElementsByClassName("text-yellow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("blogdashSectionActive");
  }, [dataPath]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px',backgroundColor:'#004c4c',color:'ffe6c8' }} className='bg-body-brain'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex" >
          <section >
            <h4 className='mb-3 custom-large-heading section-margin light-col common-textshadow' >{data.title}</h4>

          </section>
          <div className='row text-yellow pt-2 gx-5 img-spacing'>
            {data.cards.map(card => (
              <div className="col-md-6 col-lg-4 my-4" key={card.id}>
                <div className="card border-0 text-yellow bg-transparent">
                  <a href={card.link} className="text-decoration-none">
                    <div className='d-flex flex-column headings'>
                      <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2">{card.title}</span>
                      <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">{card.subtitle}</span>
                    </div>
                    <img src={card.image} alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDashboard;
