import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you are using React Router
import axios from 'axios';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Roles = ({dataPath}) => {
    const { role } = useParams();
    const [rolesData, setRolesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jobData, setJobData] = useState([]);
    const [sections, setSections] = useState([]);
    const [wideImage, setWideImage] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [jobLabel, setJobLabel] = useState('');
    const [sectionHeading, setSectionHeading] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [message, setMessage] = useState(''); // State to handle messages

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(dataPath);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setBgColor(data.backgroundColor);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dataPath]);

    const subDomain = localStorage.getItem('subDomain');
    let schoolName = subDomain;

    if (subDomain.includes('virtupreplabs')) {
      schoolName = 'torah';
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getEducatorsDetails', {
            params: { school: schoolName },
          });
          
          if (!response.data.success) {
              setMessage(response.data.message); // Set message from the response
              setLoading(false);
              return;
          }

          const filteredData = response.data.roles.filter(sectionDetails => {
              const roleName = role.split('=')[1];
              return roleName === sectionDetails.title;
          });

          const firstItem = filteredData[0];
          setRolesData(firstItem);
          setSections(firstItem.sections);
          setJobData(firstItem.jobData);
          setSectionHeading(firstItem.title);
          setJobLabel(firstItem.label);
          setHeaderText(firstItem.headerText);
          setWideImage(firstItem.wideImage);

        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [role]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (message) {
        return <div>{message}</div>; // Render the message from the response
    }

    if (!rolesData || !jobData || !sections || !headerText || !sectionHeading) {
        return <div>Data not found!</div>;
    }

    return (
        <div className=''>
            <div style={{ height: '100%', paddingTop: '100px' }} className={bgColor}>
                <div className="gap-3 gap-md-5">
                    <div className="vp-container container-fluid">
                        <section>
                            <h4 className='mb-3 custom-large-heading section-margin common-textshadow'>{sectionHeading}</h4>
                        </section>
                        <section className='vp-section pb-5'>
                            <div className='justify-content-center'>
                                <div className='d-flex flex-column align-items-start text-left'>
                                    <p className='montser-mdm-1-5rem common-textshadow mb-3 vp-container lh-lg text-center ft-3vh'>
                                        {headerText}
                                    </p>
                                    <div className="width-100">
                                        <img src={wideImage} alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='vp-section'>
                            <div className='section-container'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-md-6 col-12 d-flex flex-column'>
                                        {jobData.map((job, index) => (
                                            <div key={index} className='mb-5'>
                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                    <div className='job-width'>
                                                        <h5 className='common-textshadow montser-mdm-1-5rem ft-4vh mb-4'>{jobLabel}</h5>
                                                        <div className='mb-3 text-start'>
                                                            <span className='montser-mdm-1-5rem common-textshadow ft-2-5vh'>{job.title}</span>
                                                        </div>
                                                        <div className='mb-3 text-start'>
                                                            <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>{job.description}</p>
                                                        </div>
                                                    </div>
                                                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="vp-container container-fluid">
                            <div className='section-container flex-column'>
                                {sections.map((section, index) => {
                                    const isEven = index % 2 === 1;
                                    const isLastSection = index === sections.length - 1;
                                    return (
                                        <section key={index} className='vp-section'>
                                            <div className={`row align-items-center ${isLastSection ? 'pb-5' : ''}`}>
                                                <div className={`col-sm-5 ms-3 ${isEven ? 'order-md-last' : ''}`}>
                                                    <div className='w-100 maxw-450 d-flex mt-5 ' style={{marginLeft:'5vw'}}>
                                                        <img src={section.imgSrc} alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                                                    </div>
                                                </div>
                                                <div className={`col-sm-6 ${isEven ? 'order-md-first' : ''} mt-5 mt-sm-0`}>
                                                    <div className='inner-container gap-5 d-flex flex-column align-items-start'>
                                                        <div>
                                                            <p className="montser-mdm-1-5rem ft-3v me-5 text-start common-textshadow" style={{marginLeft:'6vw'}}>
                                                                {section.text}
                                                            </p>
                                                            <div className='pt-3'>
                                                                {isLastSection && <MyPopupbutton />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
