import React, { useEffect, useRef, useState } from 'react';
import '../global.css';
import './videobackground.css';

const VideoBackground = ({ videoLink, overlayText, buttonText, fontColor,backgroundColor }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isJoinHovered, setIsJoinHovered] = useState(false); // Hover state for the second button

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleJoinMouseEnter = () => {
    setIsJoinHovered(true);
  };

  const handleJoinMouseLeave = () => {
    setIsJoinHovered(false);
  };

  const buttonStyle = {
    backgroundColor: isHovered ? '#1c2933' : '#ffe6c8',
    color: isHovered ? '#ffe6c8' : '#1c2933',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  // Style for the second button (Join button)
  const joinButtonStyle = {
    backgroundColor: isJoinHovered ? '#1c2933' : '#83B06D',
    color: isJoinHovered ? '#83B06D' : '#1c2933',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const handleJoinClick = () => {
    const destinationUrl = buttonText === 'Home' ? '/' : '/about-us';
    window.location.href = destinationUrl;
  };

  function splitTextByWordCount() {
    if (!overlayText) {
      return ['', ''];
    }
    const words = overlayText.split(' ');
    const midPoint = Math.floor(words.length / 2);
    return [words.slice(0, midPoint).join(' '), words.slice(midPoint).join(' ')];
  }

  const [firstLine, secondLine] = splitTextByWordCount();

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" style={{ color: fontColor }}>
      <div className="carousel-inner p-2">
        <div className="carousel-item active">
          <div className='h-100 w-100 position-absolute' style={{backgroundColor: backgroundColor,opacity:'40%'}}>
            <div className="video-overlay">
              <video ref={videoRef} autoPlay loop muted playsInline className="w-100">
                <source src={videoLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="position-relative text-center" style={{top: '30%'}}>
            <div>
            <h1 className="custom-large-heading common-textshadow mobile-heading montser-mdm-italic ft-5vh" style={{ color: fontColor }}>
              {firstLine}
            </h1>
            <h1 className="custom-large-heading common-textshadow mobile-heading montser-mdm-italic ft-5vh mt-3" style={{ color: fontColor }}>
              {secondLine}
            </h1>
            </div>
            <div className="about">
            <button
              type="button"
              className="py-2 rounded-top rounded-0 btn-lg btn-block montser-lrg-bold-0-8rem common-textshadow common-boxshadow"
              onClick={handleJoinClick}
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {buttonText}
            </button>
            <button
              type="button"
              className="py-2 rounded-top rounded-0 btn-block montser-lrg-bold-0-8rem mt-5 btn-lg common-textshadow common-boxshadow"
              style={joinButtonStyle}
              onMouseEnter={handleJoinMouseEnter}
              onMouseLeave={handleJoinMouseLeave}
            >
              Join
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
